import { getLocalStorageItem, setLocalStorageItem } from '../../../persistentStorage';
import state from '../../../store/store';
import { Dictionnary, TranslateKeyEnum } from '../../../../types';

export default class TranslateComponent {
  private langs: string[];

  readonly dictionnary: Dictionnary;

  constructor() {
    this.langs = ['fr', 'en', 'ru', 'pt', 'ar', 'es', 'zh', 'de', 'ja', 'it', 'hi', 'he', 'el', 'bg'];
    this.dictionnary = {
      current: 'fr',
      fr: {
        LISTEN_ARTICLE: 'Écouter cet article',
        POWERED_BY: 'Powered by',
        RECOMMENDATIONS: 'Recommandations',
        LOCAL_PLAYLIST: 'Playlist',
        INFO_USING_PLAYLIST: "Ajoutez des contenus à votre liste d'écoute",
        NO_TITLE: 'Sans titre',
        NO_DATE: 'Sans date',
        TIPS: 'Astuce',
        YES: 'Oui',
        NO: 'Non',
        KEEP_PLAYING: 'Reprendre la lecture ?',
        ADD_TO_PLAYLIST: 'Ajouter à la playlist',
        REMOVE: 'Supprimer',
        BACK: 'Retour',
        OPEN_ARTICLE: "Ouvrir l'article",
        INFORMATIVE_MESSAGE_PLAYER_FEATURE:
          'Cette nouvelle fonctionnalité utilise une voix synthétique générée par ordinateur. Il peut y avoir des erreurs, par exemple dans la prononciation, le sentiment et le ton',
        CONTENT_AFTER_AD: 'Votre contenu après l’annonce',
        PLAY: 'Écouter',
        PAUSE: 'Pause',
        MUTE: 'Couper le son',
        UNMUTE: 'Rétablir le son',
        TITLE: 'Titre',
        NEXT: 'Suivant',
        PREVIOUS: 'Précédent',
        MINIMIZE_PLAYER: 'Réduire le lecteur',
        ENLARGE_PLAYER: 'Agrandir le lecteur',
        REMOVE_FROM_PLAYLIST: 'Supprimer de la playlist',
        OPEN_OPTIONS_MENU: 'Ouvrir le menu options',
        CLOSE_OPTIONS_MENU: 'Fermer le menu des options',
        SHOW_BOOKMARKS: 'Afficher ma playlist',
        SHOW_RECOMMENDATIONS: 'Afficher les recommendations',
        TOGGLE_ENLARGE_MINIMIZE_PLAYER: 'Agrandir ou réduire le lecteur',
        TOGGLE_PLAY_PAUSE: 'Écouter ou mettre en pause',
        TOGGLE_OPEN_CLOSE_OPTIONS_MENU: 'Ouvrir ou fermer le menu des options',
        HANDLE_VOLUME: 'Gérer le volume',
      },
      en: {
        LISTEN_ARTICLE: 'Listen to this article',
        POWERED_BY: 'Powered by',
        RECOMMENDATIONS: 'Recommendations',
        LOCAL_PLAYLIST: 'Playlist',
        INFO_USING_PLAYLIST: 'Add content to your playlist',
        NO_TITLE: 'Untitled',
        NO_DATE: 'No date',
        TIPS: 'Tip',
        YES: 'Yes',
        NO: 'No',
        KEEP_PLAYING: 'Resume audio ?',
        ADD_TO_PLAYLIST: 'Add to playlist',
        REMOVE: 'Delete',
        BACK: 'Back',
        OPEN_ARTICLE: 'Open article',
        INFORMATIVE_MESSAGE_PLAYER_FEATURE:
          'This new feature uses a computer-generated synthetic voice. There may be some pronunciation, feeling or tone related inaccuracies',
        CONTENT_AFTER_AD: 'Your content after the ad',
        PLAY: 'Play',
        PAUSE: 'Pause',
        MUTE: 'Mute',
        UNMUTE: 'Unmute',
        TITLE: 'Title',
        NEXT: 'Next',
        PREVIOUS: 'Previous',
        MINIMIZE_PLAYER: 'Minimize the player',
        ENLARGE_PLAYER: 'Enlarge the player',
        REMOVE_FROM_PLAYLIST: 'Remove from the playlist',
        OPEN_OPTIONS_MENU: 'Open the options menu',
        CLOSE_OPTIONS_MENU: 'Close the options menu',
        SHOW_BOOKMARKS: 'Show bookmarks',
        SHOW_RECOMMENDATIONS: 'Show recommendations',
        TOGGLE_ENLARGE_MINIMIZE_PLAYER: 'Enlarge or minimize the player',
        TOGGLE_PLAY_PAUSE: 'Play or pause',
        TOGGLE_OPEN_CLOSE_OPTIONS_MENU: 'Open or close the options menu',
        HANDLE_VOLUME: 'Handle the volume',
      },
    };
  }

  /**
   * It gets the language of the player from the HTML element and sets the language of the
   * dictionnary
   * @returns The language of the player.
   */
  getLang() {
    let { lang } = state.config;
    // FIXME: dirty hack to fix interdependency between state and translate
    if (!lang) {
      lang = 'fr';
    }
    lang = lang.replace(/\s/g, '');
    const { langs } = this;
    if (langs.includes(lang)) {
      this.dictionnary.current = lang;
    }
    return lang;
  }

  getTranslation(paramLang: string, key: TranslateKeyEnum) {
    let lang = paramLang;
    const { dictionnary } = this;
    if (lang === 'current') {
      lang = this.getLang();
    }
    if (!lang || !key || !dictionnary[lang] || !dictionnary[lang][key]) return;
    return dictionnary[lang][key];
  }

  /**
   * It gets the current language, gets all the elements with a data-translate attribute, and then
   * replaces the innerHTML of those elements with the corresponding translation
   * @returns the value of the variable currentIndex.
   */
  updateLangToUI() {
    this.getLang();
    const currentIndex = this.dictionnary.current;
    const translations = [];
    if (!currentIndex || !this.dictionnary || (this.dictionnary && !this.dictionnary[currentIndex])) return;
    Object.keys(this.dictionnary[currentIndex]).forEach((key) => {
      const translationsFound = document.querySelectorAll(`[data-translate='${key}'`);
      translationsFound.forEach((translationFound) => {
        translations.push({ element: translationFound, key });
      });
    });
    translations.forEach((translation) => {
      const translationElement = translation;
      translationElement.element.innerHTML = this.dictionnary[currentIndex][translation.key];
    });
  }

  saveLangToStorage() {
    const etxSettings = getLocalStorageItem('etx-settings');
    etxSettings.lang = this.getLang();
    setLocalStorageItem('etx-settings', etxSettings);
  }

  getLangFromStorage() {
    const { lang } = getLocalStorageItem('etx-settings');
    return lang;
  }
}
