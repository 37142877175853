import { debug, fetchRetry, getLocalStorage, setLocalStorage } from '../utils/utils';
import { UrlToAudioCache, UrlToAudioCacheEntity, UrlToAudioRes } from '../../types';
import trackingStore from '../store/tracking-store';
import state from '../store/store';

const URL_TO_AUDIO_NAME = 'url-to-audio';
const ETX_CACHE_NAME = 'etx-cache';
const ETX_CACHE_DEFAULT_VALUE = {};
const ETX_CACHE_URL_TO_AUDIO_DEFAULT_VALUE = [];

/**
 * It fetches the data from the API, and if it's not already in the cache, it stores it in the cache
 * @param {string} url - the url to fetch
 * @param {any} payload - the payload of the request
 * @returns The data is being returned.
 */
async function fetchUrlToAudioCache(
  url: string,
  payload: { body: string; method: string; headers: Headers },
  from?: string,
): Promise<UrlToAudioRes> {
  if (from) {
    console.log('fetchUrlToAudioCache', from);
  }
  let data;
  let etxCache = getCacheFromLocalStorage();
  let urlToMatch = '';
  let versionToMatch = '';
  let body = null;
  if (payload.body) {
    body = JSON.parse(payload.body);
    urlToMatch = body?.url;
    versionToMatch = body?.version;
  } else {
    const newUrl = new URL(url);
    const params = new URLSearchParams(newUrl.search);
    urlToMatch = params.get('url');
    body = { version: '' };
  }
  const requestCached = etxCache.urlToAudio.find((elm) => isResourceCached(elm, { url: urlToMatch, version: versionToMatch }));
  if (requestCached) {
    debug({ type: URL_TO_AUDIO_NAME, message: 'Getting data from cache' });
    data = requestCached;
    trackingStore.resourceId = data.id;
  } else {
    debug({ type: URL_TO_AUDIO_NAME, message: 'Fetching datas', from: state.config.apiKey ? 'Scrapping mode' : 'API mode' });

    data = await fetchRetry(url, payload);

    if (data && data.status === 'success' && (body.version || body.version === '')) {
      data = { ...data, version: body.version };
      trackingStore.resourceId = data.id;
      debug({ type: URL_TO_AUDIO_NAME, message: 'Datas received', from: state.config.apiKey ? 'Scrapping mode' : 'API mode' });
      etxCache = getCacheFromLocalStorage();
      etxCache.urlToAudio.push(data);
      setLocalStorage(ETX_CACHE_NAME, etxCache);
    } else {
      return null;
    }
  }
  return data === ETX_CACHE_URL_TO_AUDIO_DEFAULT_VALUE ? null : data;
}

function isResourceCached(cacheEntry: UrlToAudioCacheEntity, requestBody: { url: string; version: string }) {
  const isUrlEqual = cacheEntry?.url === requestBody?.url;
  if (!requestBody?.version) {
    return isUrlEqual;
  }
  return isUrlEqual && cacheEntry?.version === requestBody?.version;
}

/**
 * It gets the cache from local storage, and if it doesn't exist, it creates it
 * @returns the value of the etxCache variable.
 */
function getCacheFromLocalStorage() {
  const etxCache: UrlToAudioCache = getLocalStorage(ETX_CACHE_NAME, ETX_CACHE_DEFAULT_VALUE);
  if (!etxCache.urlToAudio) {
    etxCache.urlToAudio = ETX_CACHE_URL_TO_AUDIO_DEFAULT_VALUE;
  }
  return etxCache;
}

export default fetchUrlToAudioCache;
