const isLocalStorageAvailable = () => {
  try {
    const testString = '__localStorage_test__';
    localStorage.setItem(testString, testString);
    localStorage.removeItem(testString);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const setLocalStorageItem = (key, data) => {
  const jsonData = JSON.stringify(data);

  try {
    localStorage.setItem(key, jsonData);
  } catch (error) {
    return {
      ok: false,
      reason: 'storageNotAvailable',
      error: error,
    };
  }

  return { ok: true };
};

const getLocalStorageItem = (key) => {
  const storedData = localStorage.getItem(key);
  if (storedData === null || storedData === undefined) {
    return [];
  }
  return JSON.parse(storedData);
};

const clearLocalStorageItem = (key) => {
  localStorage.removeItem(key);
};

export {
  isLocalStorageAvailable, setLocalStorageItem, getLocalStorageItem, clearLocalStorageItem,
};
