function syncSafeToRegularInt(syncSafeInt) {
  // eslint-disable-next-line no-bitwise
  return ((syncSafeInt & 0x7f000000) >> 3) | ((syncSafeInt & 0x007f0000) >> 2) | ((syncSafeInt & 0x00007f00) >> 1) | (syncSafeInt & 0x0000007f);
}

async function fetchAndParseMP3Metadata(url: string) {
  const response = await fetch(url, {
    headers: {
      Range: 'bytes=0-1024',
    },
  });
  if (!response.ok) {
    throw new Error(`Network response was not ok ${response.statusText}`);
  }

  // Convert the response data to an ArrayBuffer
  const arrayBuffer = await response.arrayBuffer();

  // Keeping this to ensure we're not overfetching
  console.log('METADATA PRELOAD BUFFER SIZE', arrayBuffer);

  const dataView = new DataView(arrayBuffer);

  let position = 10; // Start after the ID3v2 header

  while (position < dataView.byteLength) {
    const frameId = String.fromCharCode(
      dataView.getUint8(position),
      dataView.getUint8(position + 1),
      dataView.getUint8(position + 2),
      dataView.getUint8(position + 3),
    );
    const frameSize = syncSafeToRegularInt(dataView.getUint32(position + 4, false));

    if (frameId === 'TXXX') {
      position += 10; // Skip the frame header
      const encoding = dataView.getUint8(position);
      position++; // Move past the encoding byte

      let description = '';

      if (encoding === 0x03) {
        // UTF-8
        while (dataView.getUint8(position) !== 0) {
          description += String.fromCharCode(dataView.getUint8(position));
          position++;
        }
      } else {
        // Handle other encodings if necessary
      }

      // Skip the null separator
      position++;
      if (description === 'comment') {
        let value = '';

        if (encoding === 0x03) {
          // UTF-8
          const textDecoder = new TextDecoder('utf-8');
          const valueStart = position;
          while (position < dataView.byteLength && position < frameSize + 10 + valueStart && dataView.getUint8(position) !== 0) {
            position++;
          }
          const valueBuffer = new Uint8Array(arrayBuffer, valueStart, position - valueStart);
          value = textDecoder.decode(valueBuffer);
        } else {
          // Handle other encodings if necessary
          while (position < dataView.byteLength && position < frameSize + 10 && dataView.getUint8(position) !== 0) {
            value += String.fromCharCode(dataView.getUint8(position));
            position++;
          }
        }

        return JSON.parse(value);
      }
    } else {
      position += 1;
    }
  }

  return null; // Comment tag not found
}

export default fetchAndParseMP3Metadata;
