import Player from 'openplayerjs';
import { Config, Queued } from '../../types';

const defaultConfig: Config = {
  lang: 'fr',
  apiKey: null,
  preload: null,
  disabled: false,
  duration: 0,
  ads: {
    enabled: false,
    channelId: null,
    gdprConsent: null,
    contentTier: null,
    placement: null,
    keywords: null,
    trackColor: null,
  },
  color: {
    main: null,
    secondary: null,
    mainHover: null,
    btnHover: null,
    btnActive: null,
    time: null,
  },
  font: {
    listenText: null,
  },
  playlist: {
    enabled: false,
    name: null,
  },
  customClasses: {
    launcherIcon: null,
    players: null,
  },
  informationTooltip: {
    enabled: true,
    text: null,
  },
  rss: {
    enabled: false,
    url: null,
  },
  informationText: {
    fr: {
      article: null,
      ad: null,
    },
    en: {
      article: null,
      ad: null,
    },
  },
  buttons: {
    play: {
      color: null,
      radius: null,
      align: null,
      icon: null,
    },
  },
  labels: {
    listenArticle: {
      text: null,
      color: null,
      colorHover: null,
      font: null,
    },
    credits: {
      enabled: true,
      text: null,
      color: null,
    },
  },
  tracks: {
    article: {
      color: null,
      backgroundColor: null,
    },
    volume: {
      color: null,
      backgroundColor: null,
    },
  },
};

const initialState: State = {
  adCounter: 0,
  isSmartPlayer: false,
  htmlMediaElement: null,
  playerInstance: null,
  playerContainer: null,
  resourceId: null,
  config: defaultConfig,
  earlyStart: false,
  autoStart: false,
  queued: { shouldAddToPlaylist: [], shouldOpen: false, shouldPlay: '' },
};
interface State {
  adCounter: number;
  isSmartPlayer: boolean;
  earlyStart: boolean;
  autoStart: boolean;
  // since the player can be either audio or video, we have to type it as a MediaElement
  htmlMediaElement: HTMLMediaElement | null;
  playerInstance: Player;
  // corresponds to <div class="etx-player"></div>
  playerContainer: HTMLDivElement;
  resourceId: string;
  config: Config;
  queued: Queued;
}

const state: State = initialState;
export default state;
