async function fetchAndParseWavMetadata(url: string) {
  // Fetch data from the given URL
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Network response was not ok ${response.statusText}`);
  }

  // Convert the response data to an ArrayBuffer
  const arrayBuffer = await response.arrayBuffer();

  const dataView = new DataView(arrayBuffer);

  let position = 12; // Start after the RIFF header

  while (position < dataView.byteLength) {
    const chunkId = String.fromCharCode(
      dataView.getUint8(position),
      dataView.getUint8(position + 1),
      dataView.getUint8(position + 2),
      dataView.getUint8(position + 3),
    );
    const chunkSize = dataView.getUint32(position + 4, true); // Little-endian

    position += 8; // Move past the chunk ID and size

    if (chunkId === 'LIST') {
      position += 4; // Move past the LIST type

      const endOfListChunk = position + chunkSize - 4; // Subtract the 4 bytes of the listType

      while (position < endOfListChunk) {
        const subChunkId = String.fromCharCode(
          dataView.getUint8(position),
          dataView.getUint8(position + 1),
          dataView.getUint8(position + 2),
          dataView.getUint8(position + 3),
        );
        const subChunkSize = dataView.getUint32(position + 4, true);
        position += 8; // Move past the sub-chunk ID and size

        if (subChunkId === 'ICMT') {
          const textDecoder = new TextDecoder('utf-8');
          let subChunkData = textDecoder.decode(new Uint8Array(arrayBuffer, position, subChunkSize));

          // Sanitize the string
          subChunkData = subChunkData.replace(/\0/g, '').trim();

          const parsedData = JSON.parse(subChunkData);
          return parsedData;
        }

        position += subChunkSize;
      }
    } else {
      position += chunkSize;
    }
  }

  return null; // Metadata not found
}

export default fetchAndParseWavMetadata;
