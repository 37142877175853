import { TrackingParams } from '../../types';
import { debug, getCanonicalUrl, getLocalStorage, getResourceIdFromResourceUrl, locationsAreDistinct } from '../utils/utils';
import trackingStore from '../store/tracking-store';
import fetchAndParseMP3Metadata from '../read-mp3-metadata';
import fetchAndParseWavMetadata from '../read-wav-metadata';

const events = {};
let audioSource: 'api-v1' | 'api-v2' | 'unknown';
let generationId;

const postTrackingEvent = (payload: TrackingParams) => {
  const params = {
    method: 'POST',
    headers: new Headers({
      'content-type': 'application/json',
    }),
    body: JSON.stringify(payload),
  };

  return fetch(process.env.TRACKING_URL, params).catch((err) => console.error(err));
};

const sendTrackingEvent = async ({ eventType, eventValue }: { eventType: string; eventValue: string | number | boolean }) => {
  const htmlMediaElement = document.querySelector('.ep-player') as HTMLElement;
  const playerContainer = document.querySelector('.etx-player') as HTMLElement;
  const urlInPlayer = playerContainer.getAttribute('data-url');
  if (!generationId) {
    generationId = await getGenerationVersionFromMetadata(urlInPlayer);
  }

  const resourceId =
    generationId ??
    trackingStore.resourceId ??
    htmlMediaElement?.dataset?.r ??
    playerContainer.dataset.resourceId ??
    getResourceIdFromResourceUrl(trackingStore.resourceUrl);
  const isFixed = !!document.querySelector('.etx-player.fixed');
  const isBubble = !!document.querySelector('.etx-facade-bubble');
  const isSmartPlayer = isBubble || isFixed;
  const longSessionId = isSmartPlayer ? getLongSessionId() : null;
  const deviceId = getDeviceId();
  const url = getCanonicalUrl();
  const urlFull = locationsAreDistinct() ? document.location.href : window.top.location.href;
  const audioUrl = htmlMediaElement?.getAttribute('src');
  const contentTier = <'free' | 'metered' | 'locked'>playerContainer.dataset.contentTier;
  audioSource = (playerContainer.dataset.audioSource as 'api-v1' | 'api-v2' | 'unknown') ?? (generationId ? 'api-v2' : 'api-v1');

  if (!url) {
    debug({ message: 'Canonical url missing', from: url, type: 'error' });
  }
  if (!urlFull) {
    debug({ message: 'Real url missing', from: url, type: 'error' });
  }
  events[eventType] = eventValue;
  return postTrackingEvent({
    deviceId,
    url,
    urlFull,
    events,
    isSmartPlayer,
    sessionId: trackingStore.sessionId,
    resourceId,
    audioUrl,
    longSessionId,
    contentTier,
    audioSource,
  });
};

const getDeviceId = (): string => {
  const etxSettings = getLocalStorage('etx-settings');
  return etxSettings?.tracking?.permanentSession?.id ?? crypto.randomUUID();
};

const getLongSessionId = (): string => {
  const etxSettings = getLocalStorage('etx-settings');
  return etxSettings?.tracking?.longSession?.id;
};

const getGenerationVersionFromMetadata = async (url: string) => {
  let metadata: { generationId: number };
  if (url?.endsWith('.mp3')) {
    try {
      metadata = await fetchAndParseMP3Metadata(url);
    } catch (e) {
      console.error(e);
      metadata = null;
    }
    return metadata?.generationId;
  }
  if (url?.endsWith('.wav')) {
    generationId = fetchAndParseWavMetadata(url);
    return generationId;
  }
};

export default sendTrackingEvent;
