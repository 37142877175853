let timerUserIsInactive = 0;

/* It's a class that contains a bunch of utility functions that are used throughout the player */
/**
 * It returns a promise that resolves after a given number of milliseconds
 * @param ms - The number of milliseconds to wait before resolving the promise.
 * @returns A promise that will resolve after the specified time.
 */
export function sleep(ms: number): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

/**
 * It returns the bounding rectangle of the element
 * @param el - The element to be offset.
 * @returns The bounding rectangle of the element.
 */
export function offsetElement(el: HTMLElement) {
  return el.getBoundingClientRect();
}

/**
 * It makes an element invisible
 * @param element - The element to hide.
 */
export function hideElement(element: HTMLElement) {
  if (element) {
    element.style.opacity = '0';
    element.style.position = 'absolute';
    element.style.userSelect = 'none';
    element.style.pointerEvents = 'none';
  }
}

/**
 * It makes an element visible
 * @param element - The element to show.
 */
export function showElement(element: HTMLElement) {
  if (element) {
    element.style.opacity = '1';
    element.style.position = 'inherit';
    element.style.userSelect = 'inherit';
    element.style.pointerEvents = 'inherit';
  }
}

/**
 * Given a set of hours, minutes, and seconds, return the total number of seconds.
 * @param [params] - An object containing the following properties:
 * @returns The duration in seconds.
 */
export function getDurationInSeconds({ hours, minutes, seconds }: { hours: number; minutes: number; seconds: number }) {
  return (hours || 0) * 3600 + (minutes || 0) * 60 + seconds;
}

/**
 * It takes a duration in seconds and returns a string in the format of HH:MM:SS
 * @param durationInSeconds - The duration of the media in seconds.
 * @returns the duration in hours, minutes, and seconds.
 */
export function getDurationForClient(durationInSeconds) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds - hours * 3600) / 60);

  const seconds = durationInSeconds - hours * 3600 - minutes * 60;

  return Number(hours)
    ? addZeroToNumber(hours) + ':' + addZeroToNumber(minutes) + ':' + addZeroToNumber(seconds)
    : addZeroToNumber(minutes) + ':' + addZeroToNumber(seconds);
}

/**
 * It returns a random integer between 0 and the max value you pass in
 * @param max - The maximum number that can be returned.
 * @returns A random number between 0 and the max number.
 */
export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

/**
 * It returns a random number between the min and max values
 * @param min - The minimum number you want to generate.
 * @param max - The maximum number you want to generate.
 * @returns A random number between min and max.
 */
export function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

/**
 * It sets the canvas size to the given width and height
 * @param canvas - The canvas element to resize.
 * @param width - The width of the canvas in pixels.
 * @param height - The height of the canvas in pixels.
 */
export function setCanvasSize(canvas: HTMLCanvasElement, width: string, height: string) {
  const { style } = canvas;

  style.width = '' + width + 'px';
  style.height = '100%';

  canvas.width = Number(width);
  canvas.height = Number(height);
}

/**
 * It returns the width and height of the client's screen
 * @returns An object with two properties, width and height.
 */
export function getClientScreenDimension() {
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  return { width, height };
}

/**
 * If the element has the class 'disabled', remove it. Otherwise, add it
 * @param elem - The element you want to toggle the disabled class on.
 * @param bool - true, false, or undefined. If true, the element will be disabled. If false, the
 * element will be enabled. If undefined, the element will be toggled.
 * @returns the value of the function.
 */
// TODO: This is not a toggle function, its a set function, it needs proper naming or different logic
// usage indicates that this is a set method since boolean is always provided
export function toggleDisabled(elem: HTMLElement, bool: boolean) {
  if (!elem) return;
  if (bool === true) {
    elem.classList.add('disabled');
    return;
  }
  if (bool === false) {
    elem.classList.remove('disabled');
    return;
  }
  if (elem.classList.contains('disabled')) {
    elem.classList.remove('disabled');
    return;
  }
  elem.classList.add('disabled');
}

/**
 * It takes a string as an argument, and returns the value of the localStorage item with that name,
 * or an empty array if there is no such item
 * @param name - The name of the localStorage item.
 * @returns the value of the localStorage item with the name passed in as an argument. If the item
 * does not exist, it returns an empty array.
 */
export function getLocalStorage(name: string, defaultValue?) {
  if (!localStorage) return;
  return JSON.parse(localStorage.getItem(name)) || defaultValue || [];
}

/**
 * It updates the playlist counter in the playlist navigator
 * @returns The total number of items in the playlist.
 */
export function updateNavigatorPlaylistCounter() {
  let base;
  const counter = document.querySelector('.etx-player-playlist-navigator-counter');
  if (!counter) return;
  const localPlaylist = getLocalStorage('etx-playlist');
  const total = localPlaylist && localPlaylist.length ? localPlaylist.length : 0;
  counter.innerHTML = total;
}

/**
 * It removes a key from localStorage
 * @param name - The name of the localStorage item to remove.
 * @returns the value of the localStorage.removeItem(name) method.
 */
export function removeFromLocalStorage(name) {
  if (!name) return;
  localStorage.removeItem(name);
  if (name === 'etx-playlist') {
    updateNavigatorPlaylistCounter();
  }
}

/**
 * It sets the local storage
 * @param name - The name of the localStorage item.
 * @param payload - The data to be stored in the local storage.
 */
export function setLocalStorage(name, payload) {
  if (payload || payload?.audioUrl) {
    if (name === 'etx-playlist') {
      payload.index = -1;
    }
    localStorage.setItem(name, JSON.stringify(payload));
  }
  if (name === 'etx-playlist') {
    updateNavigatorPlaylistCounter();
  }
}

/**
 * It updates a key in a local storage object
 * @param localStorageName - The name of the local storage you want to update.
 * @param keyToUpdate - The key you want to update in the local storage
 * @param value - the value you want to set the key to
 */
export function updateLocalStorage(localStorageName, keyToUpdate, value) {
  const local = getLocalStorage(localStorageName);
  let payload = {
    [keyToUpdate]: value,
  };
  if (local) {
    payload = { ...local };
    payload[keyToUpdate] = value;
  }
  setLocalStorage(localStorageName, payload);
}

/**
 * It takes a localStorageName and a payload, checks if the localStorageName exists, if it does, it
 * concatenates the payload to the existing localStorageName, and then sets the localStorageName to
 * the new payload
 * @param localStorageName - The name of the local storage item you want to add to.
 * @param payload - the data you want to store in localStorage
 * @returns the value of the localStorageName key in localStorage.
 */
export function addToExistingLocalStorage(localStorageName, payload) {
  if (!localStorageName || !payload) return;
  const existingArray = getLocalStorage(localStorageName);
  if (existingArray && payload?.length) {
    payload = existingArray.concat(payload);
    setLocalStorage(localStorageName, payload);
  }
}

/**
 * It takes an array of objects, a key to compare, and a localStorage name, and returns an array of
 * objects that are unique to the localStorage array
 * @param localStorageName - The name of the local storage you want to compare to.
 * @param key - the key to compare the values of
 * @param arrayToFilter - The array you want to filter
 * @returns An array of objects that are unique to the arrayToFilter.
 */
export function compareToLocalStorageAndGetUniqueDatas(localStorageName, key, arrayToFilter) {
  const existingArray = getLocalStorage(localStorageName);
  if (!existingArray || !key || !arrayToFilter) return;
  return arrayToFilter.filter((val) => !existingArray.some((val2) => val[key] && val2[key] && val2[key] === val[key]));
}

/**
 * It takes an array of objects and returns a new array of objects with only unique values based on a
 * key
 * @param arr - The array you want to filter
 * @param key - The key to filter by.
 * @returns the unique values of the array based on the key.
 */
export function getUniqueListBy(arr, key) {
  if (!arr || !key) return;
  const unique = arr.filter((v, i, a) => a.findIndex((v2) => v2[key] === v[key]) === i);
  return unique;
}

/**
 * Wait for a specified amount of time, then resolve the promise.
 * @param delay - The amount of time to wait in milliseconds.
 * @returns A promise that will resolve after the delay.
 */
export function wait(delay): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

/**
 * It will retry a fetch request until it succeeds or until the retry count is reached
 * @param url - The URL to fetch
 * @param [options] - The options object that you would normally pass to fetch.
 * @param [retries=200] - The number of times to retry the request.
 * @param [backoff=300] - The amount of time to wait before retrying the request.
 * @returns A promise that resolves to the body of the response.
 */
export async function fetchRetry(url, options = {}, retries = 20, backoff = 2000) {
  const res = await fetch(url, options).catch((err) => {
    throw err;
  });
  if (!res.ok) return null;

  const body = await res.json();
  if (retries > 0) {
    if (body.status === 'success') {
      return body;
    }
    if (body.status === 'failed') {
      return false;
    }
    await wait(backoff);
    return fetchRetry(url, options, retries - 1, backoff);
  }
  return false;
}

export async function waitForElm(selector): Promise<any> {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      resolve(document.querySelector(selector));
    }
    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

/**
 * If the user agent is a tablet, return tablet. If the user agent is a mobile device, return mobile.
 * Otherwise, return desktop
 * @returns the device type of the user.
 */
export function deviceType() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  }
  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
}

/**
 * It takes a function and a delay in milliseconds as arguments, and returns a function that, when
 * called, will call the original function after the specified delay
 * @param method - The function you want to execute after the delay
 * @param delay - The number of milliseconds to delay
 */
export function debounce(method, delay) {
  clearTimeout(method._tId);
  method._tId = setTimeout(() => {
    method();
  }, delay);
}

/**
 * If the top of the element is above the top of the viewport, and the bottom of the element is below
 * the bottom of the viewport, and the left of the element is to the left of the left of the
 * viewport, and the right of the element is to the right of the right of the viewport, then the
 * element is in the viewport.
 * @param el - The element you want to check if it's in the viewport.
 * @returns A boolean value.
 */
export function isInViewport(el) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

/**
 * It takes two timestamps and returns an object with the number of days, hours, minutes, and seconds
 * between them
 * @param timestamp1 - The timestamp of the current time.
 * @param timestamp2 - The timestamp you want to compare to.
 * @returns An object with the difference in days, hours, minutes, and seconds.
 */
export function timeDifference(timestamp1, timestamp2) {
  if (!timestamp1 || !timestamp2) return;
  let difference = timestamp1 - timestamp2;

  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  const secondsDifference = Math.floor(difference / 1000);

  return {
    daysDifference,
    hoursDifference,
    minutesDifference,
    secondsDifference,
  };
}

/**
 * It returns the canonical URL of the current page, if it exists
 * @returns The canonical URL of the page.
 */
export function getCanonicalUrl() {
  const canonicalElem = document.querySelector("link[rel='canonical']") as HTMLAnchorElement;
  return canonicalElem?.href ? canonicalElem.href : '';
}

/**
 * If the debugMode is not activated, or if the message is not defined, then return. Otherwise, log
 * the message to the console
 * @param obj - {
 * @returns The debug function is returning the message and from parameters.
 */
export function debug(obj) {
  const { message, from, type } = obj;
  const player = document.querySelector('.etx-player') as HTMLElement;
  const debugMode = player ? player.dataset.debug : null;
  const isDebugMode = debugMode === 'true';
  let debugNode = {};
  let debugMessage = '[ETX]';
  const debugLogLimit = 100;
  if (type) {
    debugMessage += type ? '[' + type.charAt(0).toUpperCase() + type.slice(1) + ']' : '';
  }
  debugMessage += ' - ';
  if (message) {
    debugMessage += message;
  }
  const today = new Date();
  const timestamp1 = today.getTime();
  const date = today.getFullYear() + '-' + addZeroToNumber(today.getMonth() + 1) + '-' + addZeroToNumber(today.getDate());
  const time = today.getHours() + ':' + addZeroToNumber(today.getMinutes()) + ':' + addZeroToNumber(today.getSeconds());
  const fullDate = date + ' ' + time;

  let etxDebugStorage = getLocalStorage('etx-debug');
  if (etxDebugStorage.length > debugLogLimit) {
    setLocalStorage('etx-debug', etxDebugStorage.slice(0, debugLogLimit));
    etxDebugStorage = getLocalStorage('etx-debug');
  }
  const timestamp2 = etxDebugStorage[etxDebugStorage.length - 1] ? etxDebugStorage[etxDebugStorage.length - 1].timestamp : '';
  const timeDelta = timeDifference(timestamp1, timestamp2 || timestamp1);
  const { daysDifference, hoursDifference, minutesDifference, secondsDifference } = timeDelta;
  const lastDebug = daysDifference + 'd ' + hoursDifference + 'h ' + minutesDifference + 'm ' + secondsDifference + 's';
  debugNode = { date: fullDate, message: debugMessage, timestamp: today.getTime(), lastDebug };
  if (from) {
    // debugNode.context = from;
  }
  if (!isDebugMode || !isLocalhost()) {
    if (etxDebugStorage.length >= debugLogLimit) {
      etxDebugStorage.shift();
    }
    etxDebugStorage.push(debugNode);
    setLocalStorage('etx-debug', etxDebugStorage);
  }
  if (!isLocalhost() && (!debugMode || debugMode === 'false')) {
    return; // early return if not in localStorage and debugMode is not activated
  }
  if (!message) {
    return; // early return if message is not defined
  }
  if (debugMessage && !from) {
    console.log(debugMessage);
  } else if (debugMessage && from) {
    console.log(debugMessage, '| context :', from);
  }
}

/**
 * If the environment is development, return true. Otherwise, return false
 * @returns A boolean, wether the env mode is prod or dev.
 */
export function isLocalhost() {
  return process.env.NODE_ENV === 'development';
}

/**
 * It takes a URL and returns the GUID of the resource
 * @param url - The URL of the resource you want to get the ID of.
 * @returns The first match of a UUID in the URL.
 */
export function getResourceIdFromResourceUrl(url) {
  if (!url) {
    return null;
  }
  const match = url.match(/[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/g);
  if (match && match.length) {
    return match[0];
  }
  return null;
}

/**
 * If the value is less than 10, add a zero to the front of it, otherwise return the value
 * @param value - The value to be formatted.
 * @returns the value of the number if it is greater than 10, otherwise it is returning the value of
 * the number with a 0 in front of it.
 */
export function addZeroToNumber(value: number): string {
  return value < 10 ? '0' + value : String(value);
}

/**
 * It loads an image, and if the image takes longer than `waitAtLeastMs` to load, it waits for the
 * remaining time before resolving the promise
 * @param src - the image source
 * @param [waitAtLeastMs=0] - This is the minimum amount of time you want to wait before resolving
 * the promise.
 * @returns A promise that resolves to an image object or the string 'error'
 */
export async function loadImage(src, waitAtLeastMs = 0): Promise<any> {
  return new Promise((resolve) => {
    const a = window.performance.now();
    let b;
    const img = new Image();
    img.onload = async () => {
      b = window.performance.now();
      if (waitAtLeastMs) {
        const waitMs = Math.abs(waitAtLeastMs - (b - a));
        await wait(waitMs);
      }
      return resolve(img);
    };
    img.onerror = async () => {
      b = window.performance.now();
      if (waitAtLeastMs) {
        const waitMs = Math.abs(waitAtLeastMs - (b - a));
        await wait(waitMs);
      }
      return resolve('error');
    };
    img.src = src;
  });
}

/**
 * It checks if the current domain is part of the La Depeche group
 * @returns The domain of the website
 */
export function domainBelongTo() {
  let domain;
  debug({ type: 'domain', message: 'Checking domain...' });
  // The first one element in the array is for debug purpose
  const depecheDomains = [
    'madame.lefigaro.fr',
    'etxstudio.s3.eu-west-1.amazonaws.com',
    'www.ladepeche.fr',
    'www.centre-presse.fr',
    'www.lindependant.fr',
    'www.midilibre.fr',
    'www.midi-olympique.fr',
    'www.petitbleu.fr',
    'www.nrpyrenees.fr',
  ];
  const currentDomain = window.location.hostname;
  const isLaDepeche = depecheDomains.includes(currentDomain);
  if (isLaDepeche) {
    domain = 'ladepeche';
    debug({ type: 'domain', message: 'Domain is part of La Depeche' });
  } else {
    domain = 'notladepeche';
    debug({ type: 'domain', message: 'Check done' });
  }
  return domain;
}

/**
 * If the current window's location is not the same as the parent window's location, then the
 * locations are distinct
 * @returns A boolean value.
 */
export function locationsAreDistinct() {
  return window.location !== window.parent.location;
}

/**
 * If the year, month, and day of the first date are the same as the year, month, and day of the second
 * date, then the dates are on the same day.
 * @param {Date} first - The first date to compare.
 * @param {Date} second - Date - The second date to check.
 * @returns A boolean value.
 */
export function datesAreOnSameDay(first: Date, second: Date) {
  return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
}

/**
 * It takes a timeout time in milliseconds and a function as arguments. It then adds an event listener
 * to the document for each of the events in the string, and when any of those events are triggered, it
 * clears the timer and sets a new one. When the timer runs out, it calls the function passed in as an
 * argument
 * @param timeoutTimeMs - The time in milliseconds that the user has to be inactive for before the
 * function is called.
 * @param {Function} fn - Function - the function to call when the user is inactive
 */
export function detectInactiveUserEvery(timeoutTimeMs, fn) {
  'mousemove mousedown keydown touchstart wheel'.split(' ').forEach((eventType: any) => {
    document.addEventListener(eventType, () => {
      window.clearTimeout(timerUserIsInactive);
      timerUserIsInactive = window.setTimeout(() => {
        debug({ type: 'Tracking', message: 'User is inactive, generating a new LongSessionId' });
        fn();
      }, timeoutTimeMs);
    });
  });
}

function isDatasetMediaVideo(url: string): boolean {
  return url.endsWith('.mp4');
  // TODO: implement this for embed video player
  // if (url.endsWith('.mp4')) {
  //   const htmlPlayer = document.createElement('video');
  //   htmlPlayerContainer.removeChild(information);
  //   htmlPlayerContainer.style.setProperty('--playBtnAlign', playBtnAlign || '-7px');
  //   htmlPlayerContainer.style.setProperty('--timeColor', timeColor || '#fff');
  //   htmlPlayerContainer.classList.add('video-player');
  // }
}

export function createMediaElement(url: string) {
  let htmlMediaElement;
  if (isDatasetMediaVideo(url)) {
    htmlMediaElement = document.createElement('video');
  } else {
    htmlMediaElement = document.createElement('audio');
  }
  return htmlMediaElement;
}

const userAgent = window?.navigator?.userAgent?.toLowerCase();

export const IS_IPHONE = userAgent ? /iphone/i.test(userAgent) : false;

export const IS_CHROME = getNavigatorName() === 'chrome';
export const IS_CHROME_IOS = /CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent);

export function getNavigatorName() {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'opera';
  }
  if (navigator.userAgent.indexOf('Edg') !== -1) {
    return 'edge';
  }
  if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'chrome';
  }
  if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'safari';
  }
  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'firefox';
  }
  if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.DOCUMENT_NODE === true) {
    // IF IE > 10
    return 'ie';
  }
  return 'unknown';
}
