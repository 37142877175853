import { v4 as uuid } from 'uuid';
import {
  debug,
  getLocalStorage,
  setLocalStorage,
  detectInactiveUserEvery,
  datesAreOnSameDay,
} from './utils/utils';
import state from './store/store';
import sendTrackingEvent from './service/tracking';

// TODO: CHANGE NAME OF THIS FILE
let playerInstance = null;
let deviceInfo = null;
let previousAudioSrc = null;
const events = {};
// TODO: Remove this and all data associated with it once we get rid of Kinesis
const deviceInfoUrl = 'https://rraubykfmli5rcuu7u2uy7th4a0cgudg.lambda-url.eu-west-1.on.aws/';
let dateDelta = 0;

/**
 * It takes a player object and returns the percentage of the media that has been played
 * @param player - The player object
 * @returns The percentage of the media that has been played.
 */
const getCompletion = (player) => {
  let played = 0;
  for (let index = 0; index < player.played.length; index++) {
    played += player.played.end(index) - player.played.start(index);
  }
  return (played / player.duration) * 100;
};

/**
 * @param {HTMLElement} player
 * @param {Function}
 */
const addPlayingListeners = (player, handler) => {
  resetPlayerEventDataset(player);
  player.addEventListener('timeupdate', handler);
  player.addEventListener('play', handler);
  player.addEventListener('pause', handler);
};

/**
 * If the source has changed, reset the player's event dataset. If the completion is 1 and the event
 * dataset doesn't include the completion event, fire the completion event. Otherwise, if the
 * completion is greater than 0 and the event dataset doesn't include the completion event, fire the
 * completion event.
 * @param e - The event object
 */
const handlePlayingEvent = (e) => {
  const player = e.currentTarget;
  if (sourceHasChanged(player)) {
    resetPlayerEventDataset(player);
  }
  const completion = Math.round(getCompletion(player));
  if (completion === 1 && !player.dataset.e.includes(`ep-c[${completion}]`)) {
    player.dataset.e += `ep-c[${completion}]`;
    sendTrackingEvent({ eventType: 'completion', eventValue: String(0) });
  } else if (shouldSendCompletionTrackingEvent(player, completion)) {
    player.dataset.e += `ep-c[${completion}]`;
    sendTrackingEvent({ eventType: 'completion', eventValue: String(completion) });
  }
};

/**
 * This function takes a number as an argument and returns true if the number is a multiple of five,
 * and false otherwise.
 * @param number - The number to check.
 */
const isMultipleOfFive = (number: number) => number % 5 === 0;

/**
 * If the number is greater than 0 and less than or equal to 100, return true, otherwise return false.
 * @param number - The number to check
 */
const isWithinPercentageRange = (number: number) => number > 0 && number <= 100;

/**
 * If the player's dataset.e property includes the string ep-c[] then return true,
 * otherwise return false.
 * @param player - the player object
 * @param completion - The completion step you want to check.
 */
const isCompletionStepPreviouslyReached = (player, completion: number) => player.dataset.e.includes(`ep-c[${completion}]`);

/**
 * Send a completion tracking event if the completion is a multiple of 5, is within the percentage
 * range, and has not been previously reached.
 * @param player - the player object
 * @param completion - The percentage of the media that has been watched.
 */
const shouldSendCompletionTrackingEvent = (player, completion: number) => isMultipleOfFive(completion) && isWithinPercentageRange(completion) && !isCompletionStepPreviouslyReached(player, completion);

/**
 * It adds event listeners to the player for all the events that are fired by the ads manager
 * @param player - The player object.
 * @param handler - The function that will be called when the event is triggered.
 */
const addAdsListeners = (player, handler) => {
  player.addEventListener('adsimpression', handler);
  player.addEventListener('adsmute', handler);
  player.addEventListener('adsstart', handler);
  player.addEventListener('adsloaded', handler);
  player.addEventListener('adsfirstQuartile', handler);
  player.addEventListener('adsmidpoint', handler);
  player.addEventListener('adsthirdQuartile', handler);
  player.addEventListener('adscomplete', handler);
};

/**
 * It takes a string, and returns a string with the first letter capitalized
 * @param {string} name - string - The name of the event.
 */
const adsEventToCamelCase = (name: string): string => `ads${name.charAt(3).toUpperCase()}${name.slice(4)}`;

/**
 * It takes an event object, converts the event type to camel case, fires a tracking event, and then
 * fires additional tracking events based on the event type
 * @param e - The event object that is passed to the event handler.
 */
const handleAdsEvent = (e) => {
  const camelCaseAdEvent = adsEventToCamelCase(e.type);
  sendTrackingEvent({ eventType: camelCaseAdEvent, eventValue: true });
  if (camelCaseAdEvent === 'adsStart' || camelCaseAdEvent === 'adsComplete') {
    swapInformationText();
  }
  if (camelCaseAdEvent === 'adsImpression') {
    // FIXME: eventValue Shouldbe dynamic
    sendTrackingEvent({ eventType: 'adsInsertion', eventValue: 'preroll' });
    sendTrackingEvent({ eventType: 'adsDuration', eventValue: playerInstance.getAd().duration });
  }
};

/**
 * Get the length of the user's playlist from local storage.
 * @returns The length of the playlist
 */
const getUserPlaylistLength = (): number => {
  const etxPlaylist = getLocalStorage('etx-playlist');
  return etxPlaylist?.length || 0;
};

/**
 * It returns the permanent session ID from the local storage
 * @returns A string
 */
const getPermanentSessionId = (): string => {
  const etxSettings = getLocalStorage('etx-settings');
  return etxSettings?.tracking?.permanentSession?.id;
};

/**
 * It gets the long session id from local storage
 * @returns A string
 */
const getLongSessionId = (): string => {
  const etxSettings = getLocalStorage('etx-settings');
  return etxSettings?.tracking?.longSession?.id;
};

/**
 * It returns the length of the etx-recommendations array in localStorage
 * @returns The length of the array of recommendations
 */
const getUserRecommendationsLength = (): number => {
  const etxRecommendations = getLocalStorage('etx-recommendations');
  return etxRecommendations?.length || 0;
};

/**
 * It returns the value of the data attribute `data-v` from the `playerContainer` element
 * @returns The player version.
 */
const getPlayerVersion = () => {
  const playerVersion = state.playerContainer.dataset.v;
  return playerVersion;
};

/**
 * It returns the string 'smart' if the isSmartPlayer property of the state object is true, and
 * 'simple' otherwise
 * @returns the value of the isSmartPlayer property of the state object.
 */
const getPlayerGeneration = () => {
  const { isSmartPlayer } = state;
  return isSmartPlayer ? 'smart' : 'simple';
};

/**
 * It returns the origin of the current track, if it exists
 */
const getCurrentTrackOrigin = () => getCurrentTrack()?.origin;

/**
 * It returns the originType property of the current track, if it exists
 */
const getCurrentTrackOriginType = () => getCurrentTrack()?.originType;

/**
 * It returns an object with statistics related to the player:
 */
const getFireTrackingStats = () => ({
  userPlaylistLength: getUserPlaylistLength(),
  recommendationsLength: getUserRecommendationsLength(),
  playerVersion: getPlayerVersion(), // TEMPORARY The version is the SAME for v1 or v2 and should'nt.
  playerGeneration: getPlayerGeneration(),
  currentTrackOrigin: getCurrentTrackOrigin(),
  currentTrackOriginType: getCurrentTrackOriginType(),
  permanentSessionId: getPermanentSessionId(),
  longSessionId: getLongSessionId(),
});

/**
 *
 * @param {any} player
 */
const initPlayerEventsListening = async (player) => {
  handleTrackingId();
  const deviceInfoFetch = await fetch(deviceInfoUrl);
  deviceInfo = await deviceInfoFetch.json();

  dateDelta = Date.now() - Date.parse(deviceInfo.date);

  playerInstance = player;
  const htmlPlayer = player.getElement();
  addPlayingListeners(htmlPlayer, handlePlayingEvent);
  addAdsListeners(htmlPlayer, handleAdsEvent);
  htmlPlayer.dataset.initState = 'done';
};

/**
 * It resets the event data attribute of a player
 * @param player - The player object.
 */
const resetPlayerEventDataset = (player) => {
  player.dataset.e = '';
};

/**
 * If the audio source has changed, update the previousAudioSrc variable and return true. Otherwise,
 * return false
 * @param player - The audio player element.
 * @returns A boolean value.
 */
const sourceHasChanged = (player) => {
  if (previousAudioSrc !== player.getAttribute('src')) {
    previousAudioSrc = player.getAttribute('src');
    return true;
  }
  return false;
};

/**
 * It removes all events that are not ad events or the display event
 */
export const removeTrackImplicitEvents = () => {
  for (const key in events) {
    if (isAdEvent(key) || key === 'display') {
      delete events[key];
    }
  }
};

/**
 * If the first three characters of the eventName are 'ads', return true, otherwise return false.
 * @param eventName - The name of the event.
 */
const isAdEvent = (eventName) => eventName.substring(0, 3) === 'ads';

/**
 * It swaps the text of the element with the class `ep-information-text` between the text in the
 * `data-information-text` attribute and the text in the `data-ads-text` attribute
 */
const swapInformationText = () => {
  const informationTextEl = document.querySelector<HTMLElement>('.ep-information-text');
  const adsEnabled = informationTextEl.classList.contains('ads-enabled');
  const { informationText } = informationTextEl.dataset;
  const { adsText } = informationTextEl.dataset;
  const text = adsEnabled ? informationText : adsText;
  informationTextEl.innerHTML = text;
  if (adsEnabled) {
    informationTextEl.classList.remove('ads-enabled');
  } else {
    informationTextEl.classList.add('ads-enabled');
  }
};

/**
 * It returns the value of the local storage item with the key 'etx-current-track'
 */
const getCurrentTrack = () => getLocalStorage('etx-current-track');

/**
 * Generate a tracking ID, and if the user is inactive for 30 minutes, generate a long session ID.
 */
function handleTrackingId() {
  generateTrackingIds();
  detectInactiveUserEvery(30 * 60000, generateLongSessionId);
}

/**
 * It initializes the tracking and checks if the long session id needs to be regenerated
 */
function generateTrackingIds() {
  initTracking();
  checkIfLongSessionIdNeedTobeRegenerated();
}

/**
 * It checks if the tracking ids are already set in the local storage, if not, it creates them
 */
function initTracking() {
  debug({ type: 'Tracking', message: 'Check if the tracking ids need to be generated' });
  const etxSettings = JSON.parse(localStorage.getItem('etx-settings')) || {};
  if (!etxSettings.tracking) {
    debug({ type: 'Tracking', message: 'No tracking node found in the settings, creating one and setting the values' });
    etxSettings.tracking = {
      longSession: {
        id: uuid(),
        timestamp: new Date(),
      },
      permanentSession: {
        id: uuid(),
        timestamp: new Date(),
      },
    };
    setLocalStorage('etx-settings', etxSettings);
  }
}

/**
 * If the current date is not the same as the date stored in the local storage, then generate a new
 * long session id
 */
function checkIfLongSessionIdNeedTobeRegenerated() {
  const etxSettings = getLocalStorage('etx-settings');
  const isDateTheSameDay = datesAreOnSameDay(new Date(), new Date(etxSettings.tracking.longSession.date));
  if (!isDateTheSameDay) {
    generateLongSessionId();
  }
}

/**
 * It generates a new long session ID and resets the timestamp
 */
function generateLongSessionId() {
  const etxSettings = getLocalStorage('etx-settings');
  etxSettings.tracking.longSession.id = uuid();
  etxSettings.tracking.longSession.date = new Date();
  setLocalStorage('etx-settings', etxSettings);
  debug({ type: 'Tracking', message: 'Generate new longSessionId and reset the timestamp' });
}

export { initPlayerEventsListening, handleTrackingId, getFireTrackingStats };
